import { applyMiddleware, createStore } from 'redux'
import { useSelector, TypedUseSelectorHook } from 'react-redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './rootReducer'
import User from "./type/User";
import Researcher from "./type/Researcher";

type state = {
  sidebarShow: boolean
  sidebarUnfoldable: boolean
  asideShow: boolean
  theme: string
  user?: User
  researcher?: Researcher
}

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
)
export default store

export const useTypedSelector: TypedUseSelectorHook<state> = useSelector
