export const THEME_SET = 'theme/SET'

const initialState = 'default'

type args = { type?: string; value: string }

export const reducer = (state = initialState, { type, value }: args): string => {
  switch (type) {
    case THEME_SET:
      return value
    default:
      return state
  }
}
