import { RESEARCHER_SET } from './actions'
import Researcher from '../../type/Researcher'

export type researcherAction = {
  type: string
  researcher: Researcher|null
}

const initialState = null
export const reducer = (state = initialState, action: researcherAction): Researcher|null => {
  switch (action.type) {
    case RESEARCHER_SET:
      return action.researcher

    default:
      return state
  }
}
