import React from 'react'
import {
  CAlert,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer, CFormFeedback, CFormInput, CFormLabel, CInputGroup, CLoadingButton,
  CRow, CTooltip,
} from '@coreui/react-pro'
import logo from "../assets/images/logo-dark.png";
import api from "../api";
import ValidationViolationsParser from "../utils/ValidationViolationsParser";

const SampleProjectRegistration = (): JSX.Element => {
  const [email, setEmail] = React.useState('');
  const [firstname, setFirstname] = React.useState('');
  const [errors, setErrors] = React.useState<{[key: string]: string[]}>({});
  const [regulations, setRegulations] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [registered, setRegistered] = React.useState(false);

  const submit = () => {
    setSubmitting(true)
    setErrors({})

    api.researcher.registerToSampleProject({
      email,
      firstname,
    })
      .then(res => {
        setRegistered(true)
      })
      .catch(res => {
        if(res?.response?.status === 422){
          setErrors(ValidationViolationsParser(res?.response?.data?.violations))
        }
      })
      .finally(() => setSubmitting(false))
  }

  return <CCard className="p-4">
    <CCardBody>
      <img className="d-block mx-auto" src={logo} style={{height: '100px'}} />

      {
        registered
          ? <CAlert color={'success'}>
            <span>Dziękujemy za rejestracje. Wysłaliśmy na Twój adres email <strong>{email}</strong> link z dostępem do projektu pokazowego</span>
          </CAlert>
          : <React.Fragment>
            <h4 className="text-center mb-4">Uzyskaj dostęp<br /> do projektu pokazowego</h4>
            <CInputGroup className="mb-3">
              <CFormInput placeholder="E-mail"
                          invalid={!!errors?.email}
                          autoComplete="email"
                          onChange={e => setEmail(e.target.value)}
                          value={email}
              />
              {errors?.email && <CFormFeedback invalid>{errors?.email.join('; ')}</CFormFeedback>}
            </CInputGroup>
            <CInputGroup className="mb-3">
              <CFormInput invalid={!!errors?.firstname} placeholder="Imię" autoComplete="firstname" onChange={e => setFirstname(e.target.value)} value={firstname} />
              {errors?.firstname && <CFormFeedback invalid>{errors?.firstname.join('; ')}</CFormFeedback>}
            </CInputGroup>
            <div className="d-flex flex-row flex-nowrap align-items-center cursor-pointer mb-3" onClick={() => setRegulations(!regulations)}>
              {
                regulations
                  ? <i className="far fa-check-square" />
                  : <i className="far fa-square" />
              }
              <CFormLabel className="ms-2 mb-0 cursor-pointer text-muted" style={{ lineHeight: '15px' }}><small>Administratorem danych osobowych jest SW Research sp. z o.o. z siedzibą w Warszawie ul. Jarosława Dąbrowskiego 64a, 02-561 Warszawa. Zostaną one przetworzone, zgodnie z naszą <a className="text-secondary" href="https://swquality.pl/klauzula-informacyjna" rel="noreferrer" target={'_blank'}>polityką przetwarzania danych osobowych</a>, w celach przekazywania informacji marketingowych i promocyjnych oraz w celach analitycznych.</small></CFormLabel>
            </div>
            <CLoadingButton
              loading={submitting}
              disabled={!regulations}
              color="primary"
              onClick={submit}
              className="px-4 w-100 d-block"
            >
              Uzyskaj dostęp
            </CLoadingButton>
          </React.Fragment>
      }
    </CCardBody>
  </CCard>}

export default SampleProjectRegistration
