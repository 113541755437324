import {isBoolean, isObject, isUndefined} from "lodash";

const parseObjectToUrlParams = (params: {[key: string]: string | number | undefined}): string => {
  if(!isObject(params)){
    return '';
  }
  return Object.keys(params).map<string|undefined>(key => {
    let value = params[key];

    if(isUndefined(value)){
      return undefined
    }

    if(isBoolean(value)){
      value = value ? '1' : '0';
    }

    return key + '=' + value;
  }).join('&');
}

export default parseObjectToUrlParams;
