import User from '../../type/User'
import {userAction} from "./reducer";

export const USER_SET = 'user/SET'

export function setUser(user: User|null): userAction {
  return {
    type: USER_SET,
    user,
  }
}
