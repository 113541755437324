import axios from "axios";

const setAuthorizationHeader = (token: string|null): void => {
  axios.defaults.headers.common['Cache-Control'] = 'no-cache';

  if(token){
    axios.defaults.headers.common.authorization = `Bearer ${token}`;
  }else{
    delete axios.defaults.headers.common.authorization;
  }
}

export default setAuthorizationHeader;
