import User from "../type/User";
import Researcher from "../type/Researcher";
import {USER_SET} from "../reducers/user/actions";
import {RESEARCHER_SET} from "../reducers/researcher/actions";
import {userAction} from "../reducers/user/reducer";
import {researcherAction} from "../reducers/researcher/reducer";

const setMe = (me: User|Researcher): userAction|researcherAction|void => {
  switch (me['@type']) {
    case 'User': {
      return {
        type: USER_SET,
        user: me,
      }
    }
    case 'Researcher': {
      return {
        type: RESEARCHER_SET,
        researcher: me,
      }
    }
  }
}

export default setMe;
