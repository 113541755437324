import React from 'react'
import {
  CAlert,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText, CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons'
import api from '../api'
import {useDispatch} from "react-redux";
import logo from "../assets/images/logo-dark.png";
import { Link } from 'react-router-dom'
import appRoutes from "../appRoutes";

const RemindPassword = (): JSX.Element => {
  const dispatch = useDispatch()
  const [email, setEmail] = React.useState('')
  const [sending, setSending] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [error, setError] = React.useState<boolean>(false);

  const send = () => {
    if(!email){
      return
    }

    setSending(true)
    setError(false)

    api.remindPassword({
      email,
    })
      .then(() => setSent(true))
      .catch(res => {
        setError(res.response.data['hydra:description'])
      })
      .finally(() => setSending(false))
  }

  return <CCard className="p-4">
    <CCardBody>
      <img className="d-block mx-auto" src={logo} style={{height: '100px'}} />

      <CForm>
        <p className="text-medium-emphasis">Przypomnij hasło</p>
        {error && <CAlert color={'danger'}>Brak adresu w bazie</CAlert>}

        {
          sent
            ? <CAlert className="text-center" color={'success'}>
              <span>Na podany adres e-mail zostało wysłane hasło</span>
              <Link className={'btn btn-primary mt-2'} to={appRoutes.login}>Przejdź do logowania</Link>
            </CAlert>
            : <React.Fragment>
              <CInputGroup className="mb-3">
                <CInputGroupText className="bg-dark">
                  <i className="fas fa-user text-white" />
                </CInputGroupText>
                <CFormInput placeholder="E-mail"
                            autoComplete="email"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                />
              </CInputGroup>
              <CRow>
                <CCol xs={6}>
                  <CLoadingButton disabled={!email} loading={sending} color="primary" onClick={send} className="px-4">Wyślij hasło</CLoadingButton>
                </CCol>
                <CCol xs={6} className="d-flex justify-content-end align-items-center">
                </CCol>
              </CRow>
            </React.Fragment>
        }
      </CForm>
    </CCardBody>
  </CCard>}

export default RemindPassword
