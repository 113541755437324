export const SIDEBAR_UNFOLDABLE_SET = 'sidebarUnfoldable/SET'

const initialState = false

type args = { type?: string; value: boolean }

export const reducer = (state = initialState, { type, value }: args): boolean => {
  switch (type) {
    case SIDEBAR_UNFOLDABLE_SET:
      return value
    default:
      return state
  }
}
