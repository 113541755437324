export const SIDEBAR_SHOW_SET = 'sidebarShow/SET'

const initialState = true

type args = { type?: string; value: boolean }

export const reducer = (state = initialState, { type, value }: args): boolean => {
  switch (type) {
    case SIDEBAR_SHOW_SET:
      return value
    default:
      return state
  }
}
