import Researcher from '../../type/Researcher'
import {researcherAction} from "./reducer";

export const RESEARCHER_SET = 'researcher/SET'

export function setResearcher(researcher: Researcher|null): researcherAction {
  return {
    type: RESEARCHER_SET,
    researcher,
  }
}
