export const ASIDE_SHOW_SET = 'asideShow/SET'

const initialState = false

type args = { type?: string; value: boolean }

export const reducer = (state = initialState, { type, value }: args): boolean => {
  switch (type) {
    case ASIDE_SHOW_SET:
      return value
    default:
      return state
  }
}
