import React from 'react'
import {
  CAlert,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
} from '@coreui/react-pro'
import logo from "../assets/images/logo-dark.png";
import {Link} from 'react-router-dom'
import appRoutes from "../appRoutes";
import {isNull} from "lodash";
import useDidMountEffect from "../utils/useDidMountEffect";
import qs from "query-string";
import api from "../api";

const ConfirmAccount = (): JSX.Element => {
  const [status, setStatus] = React.useState<boolean>();

  useDidMountEffect(() => {
    const { confirmKey } = qs.parse(window.location.search)

    if(confirmKey){
      api.researcher.confirmAccount(confirmKey)
        .then(res => {
          setStatus(res.data === 1)
        })
        .catch(res => {
          setStatus(false)
        })
    }
  })

  return <CCard className="p-4">
    <CCardBody>
      <img className="d-block mx-auto" src={logo} style={{height: '100px'}} />

      {isNull(status) && <div className="py-4 text-center">
        <i className="fas fa-spin fa-spinner fa-2x" />
      </div>}
      {status === true && <React.Fragment>
        <CAlert className="text-center" color={'success'}>Twoje konto zostało aktywowane.</CAlert>
        <div className="text-center">
          <Link className="btn btn-primary" to={appRoutes.login}>Zaloguj się</Link>
        </div>
      </React.Fragment>}
      {status === false && <React.Fragment>
        <CAlert className="text-center" color={'danger'}>Wystąpił błąd podczas aktywacji konta, skontaktuj się z nami</CAlert>
      </React.Fragment>}
    </CCardBody>
  </CCard>}

export default ConfirmAccount
