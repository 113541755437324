import React from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import {
  CAlert,
  CCard,
  CCardBody, CCardGroup,
  CCol,
  CContainer,
  CRow, CSpinner
} from "@coreui/react-pro";
import Project from "../../type/Project";
import useDidMountEffect from "../../utils/useDidMountEffect";
import api from "../../api";
import ValidationViolationsParser from "../../utils/ValidationViolationsParser";
import appRoutes from "../../appRoutes";
import {useTypedSelector} from "../../store";
import ProjectRegisterResearcherForm from "../../components/ProjectRegisterResearcherForm";
import logo from "../../assets/images/logo-dark.png";

const ProjectRegisterResearcherView = () => {
  const params = useParams<{
    registerId: string
  }>();
  const researcher = useTypedSelector((state) => state.researcher)
  const history = useHistory()

  const [project, setProject] = React.useState<null|Project>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [errors, setErrors] = React.useState<{[key: string]: string[]}>({});
  const [firstname, setFirstname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [plainPassword, setPassword] = React.useState('');
  const [creating, setCreating] = React.useState(false);
  const [created, setCreated] = React.useState(false);

  useDidMountEffect(() => {
    if(researcher){
      history.push(appRoutes.projects.list)
    }else{
      setLoading(true)
      api.project.getByRegisterId(params.registerId)
        .then(res => setProject(res.data))
        .finally(() => setLoading(false))
    }
  })

  const create = () => {
    if(!project || !project.id){
      return
    }
    setCreating(true);
    setError('')
    setErrors({});

    api.project.registerResearcher( {
      project: project['@id'],
      firstname,
      email,
      plainPassword,
    })
      .then(res => setCreated(true))
      .catch(res => {
        if(res?.response?.status === 422){
          setErrors(ValidationViolationsParser(res?.response?.data?.violations))
        }
        if(res?.response?.status === 500){
          setError(res?.response?.data['hydra:description']);
        }
      })
      .finally(() => setCreating(false))
  }

  if(researcher){
    return <div>zalogowany, przycisk z nadaniem dostępu</div>
  }else{

  }

  return <CContainer>
    <CRow className="justify-content-center">
      <CCol md={8}>
        <CCardGroup>
          <CCard className="p-4">
            <CCardBody>
              {
                loading
                  ? <CSpinner className="d-block mx-auto" />
                  : !project
                    ? <CAlert color={'danger'}>Błędny identyfikator projektu</CAlert>
                    : <React.Fragment>
                      <ProjectRegisterResearcherForm
                        project={project}
                        researcher={researcher}
                      />
                      <div className="mt-2 text-center">
                        <Link className="text-secondary" to={appRoutes.remindPassword}>Nie pamiętasz hasła?</Link>
                      </div>
                    </React.Fragment>
              }
            </CCardBody>
          </CCard>
          <CCard
            className="py-5"
            style={{ width: '44%' }}
          >
            <CCardBody className="d-flex align-items-center justify-content-center">
              <img src={logo} />
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
};

export default ProjectRegisterResearcherView;
