import React from 'react';
import {Redirect, Route, RouteProps} from "react-router-dom";
import {useTypedSelector} from "../store";
import appRoutes from "../appRoutes";
import Login from "../views/Login";

const AuthRoute = (props: RouteProps): JSX.Element => {
  const user = useTypedSelector(store => store.user)
  const researcher = useTypedSelector(store => store.researcher)

  if(!user && !researcher){
    return <div className="login-page">
      <div className="min-vh-100 d-flex flex-row align-items-center">
        <div className="login-page-inner">
          <div className="box-placement">
            <Login />
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <Route {...props} />
  );
};

export default AuthRoute;
