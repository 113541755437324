import React from 'react'
import {
  CAlert,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText, CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import api from '../api'
import setAuthorizationHeader from "../utils/setAuthorizationHeader";
import {useDispatch} from "react-redux";
import setMe from "../utils/setMe";
import logo from "../assets/images/logo-dark.png";
import { Link } from 'react-router-dom'
import appRoutes from "../appRoutes";

const Login = (): JSX.Element => {
  const dispatch = useDispatch()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [logging, setLogging] = React.useState(false)
  const [error, setError] = React.useState<boolean>(false);

  const login = () => {
    setLogging(true)
    setError(false)

    api.auth({
      email,
      password,
    })
      .then((token) => {
        localStorage.jwt = token;
        setAuthorizationHeader(token);

        api.me()
          .then((me) => {
            dispatch(setMe(me))
            if(me && me['@type'] == 'Researcher' && me.sampleProject){
              window.location.href = appRoutes.projectMeasurement.view('af49068a-0031-44f0-80ba-fbacd836fb91')
            }
          })
      })
      .catch(res => {
        if(res.response.status === 401){
          setError(true)
        }
      })
      .finally(() => setLogging(false))
  }

  return (
    <CCard className="p-4">
      <CCardBody>
        <img className="d-block mx-auto" src={logo} style={{height: '100px'}} />
        <CForm>
          <p className="text-medium-emphasis">Zaloguj się</p>
          {error && <CAlert color={'danger'}>Dane są niepoprawne</CAlert>}
          <CInputGroup className="mb-3">
            <CInputGroupText className="bg-dark">
              <i className="fas fa-user text-white" />
            </CInputGroupText>
            <CFormInput placeholder="E-mail"
                        autoComplete="email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
            />
          </CInputGroup>
          <CInputGroup className="mb-4">
            <CInputGroupText className="bg-dark">
              <i className="fas fa-lock text-white" />
            </CInputGroupText>
            <CFormInput
              type="password"
              placeholder="Hasło"
              autoComplete="password"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
          </CInputGroup>
          <CRow>
            <CCol>
              <CLoadingButton loading={logging} color="primary" onClick={login} className="px-4 fw-bold">ZALOGUJ</CLoadingButton>
              <Link className="text-secondary ms-3 fw-bold" to={appRoutes.remindPassword}>Przypomnij hasło</Link>
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  )
}

export default Login
