import { combineReducers } from 'redux'

import { reducer as sidebarShowReducer } from './reducers/app/sidebarShowReducer'
import { reducer as sidebarUnfoldableReducer } from './reducers/app/sidebarUnfoldableReducer'
import { reducer as asideShowReducer } from './reducers/app/asideShowReducer'
import { reducer as themeReducer } from './reducers/app/themeReducer'
import { reducer as userReducer } from './reducers/user/reducer'
import { reducer as researcherReducer } from './reducers/researcher/reducer'

export default combineReducers({
  sidebarShow: sidebarShowReducer,
  sidebarUnfoldable: sidebarUnfoldableReducer,
  asideShow: asideShowReducer,
  theme: themeReducer,
  user: userReducer,
  researcher: researcherReducer,
})
