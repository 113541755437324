const routes = {
  home: '/',
  changelog: '/historia-zmian',
  login: '/logowanie',
  logout: '/wyloguj',
  remindPassword: '/przypomnij-haslo',
  confirmAccount: '/potwierdz-konto',
  sampleProjectRegistration: '/projekt-pokazowy',
  sampleProjectLogin: '/projekt-pokazowy-logowanie',
  history: '/historia',
  projects: {
    list: '/projekty',
    create: '/projekty/nowy',
    view: (id: number|string|undefined = ':id', tab = 'podstawowe') => `/projekty/${id}/${tab}`,
    registerResearcher: (registerId = ':registerId') => `/projekt/${registerId}`,
    youtubeStream: (id: number|string|undefined = ':id', youtubeId: number|string|undefined = ':youtubeId') => `/projekty/${id}/streamy-youtube/${youtubeId}`,
    zoomMeeting: (id: number|string|undefined = ':id', zoomId: number|string|undefined = ':zoomId') => `/projekty/${id}/spotkania-zoom/${zoomId}`,
  },
  projectMeasurement: {
    view: (id: number|string|undefined = ':id') => `/pomiar/${id}`,
  },
  researchers: {
    create: '/badacze/nowy',
    edit: (id: string) => `/badacze/${id}`,
    list: '/badacze',
  },
  respondents: {
    create: '/respondenci/nowy',
    edit: (id: string) => `/respondenci/${id}`,
    list: '/respondenci',
  },
}

export default routes
