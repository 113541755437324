type propsType = {
  propertyPath: string
  message: string
}[]

export type errorType  = {
  [key: string]: string[]
}

const ValidationViolationsParser = (violations: propsType): errorType => {
  const errors: errorType = {};

  if(violations){
    violations.forEach(violation => {
      if(!errors[violation.propertyPath]){
        errors[violation.propertyPath] = [];
      }
      errors[violation.propertyPath].push(violation.message);
    });
  }

  return errors;
}

export default ValidationViolationsParser;
