import React from 'react'
import {
  CContainer,
} from '@coreui/react-pro'
import logo from "../assets/images/logo-dark.png";
import useDidMountEffect from "../utils/useDidMountEffect";
import qs from "query-string";
import api from "../api";
import setAuthorizationHeader from "../utils/setAuthorizationHeader";
import setMe from "../utils/setMe";
import appRoutes from "../appRoutes";
import {useDispatch} from "react-redux";

const SampleProjectLogin = (): JSX.Element => {
  const dispatch = useDispatch()

  useDidMountEffect(() => {
    const { key } = qs.parse(window.location.search)


    api.researcher.getToken(key)
      .then(res => {
        api.project.clearChatsInSampleProject()

        localStorage.jwt = res.data;
        setAuthorizationHeader(res.data);

        api.me()
          .then((me) => {
            dispatch(setMe(me))
            window.location.href = appRoutes.projectMeasurement.view('af49068a-0031-44f0-80ba-fbacd836fb91')
          })
      })
  })

  return <div className="d-flex flex-column align-items-center justify-content-center">
    <img className="mb-3" src={logo} />
    <i className="fas fa-spinner fa-spin fa-4x" />
  </div>}

export default SampleProjectLogin
