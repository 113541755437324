import React from 'react';
import {Redirect, Route, RouteProps} from "react-router-dom";
import {useTypedSelector} from "../store";
import appRoutes from "../appRoutes";

const GuestRoute = (props: RouteProps): JSX.Element => {
  const user = useTypedSelector(store => store.user)
  const researcher = useTypedSelector(store => store.researcher)

  if(!!user || !!researcher){
    return <Redirect to={appRoutes.home} />
  }

  return (
    <Route {...props} />
  );
};

export default GuestRoute;
