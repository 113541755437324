import React from 'react'
import {
  AppContent,
  AppAside,
  AppSidebar,
  AppFooter,
  AppHeader,
} from '../components/index'
import GuestRoute from "../routes/GuestRoute";
import appRoutes from "../appRoutes";
import Login from "../views/Login";
import RemindPassword from "../views/RemindPassword";
import ConfirmAccount from "../views/ConfirmAccount";
import SampleProjectRegistration from "../views/SampleProjectRegistration";
import SampleProjectLogin from "../views/SampleProjectLogin";
import {Switch} from "react-router-dom";

const LoginLayout = (): JSX.Element => {
  return (
    <div className="login-page">
      <div className="min-vh-100 d-flex flex-row align-items-center">
        <div className="login-page-inner">
          <div className="box-placement">
            <Switch>
              <GuestRoute path={appRoutes.login} exact render={() => <Login />} />
              <GuestRoute path={appRoutes.remindPassword} exact render={() => <RemindPassword />} />
              <GuestRoute path={appRoutes.confirmAccount} exact render={() => <ConfirmAccount />} />
              <GuestRoute path={appRoutes.sampleProjectRegistration} exact render={() => <SampleProjectRegistration />} />
              <GuestRoute path={appRoutes.sampleProjectLogin} exact render={() => <SampleProjectLogin />} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginLayout
