import React from 'react';
import {
  CAlert,
  CForm,
  CFormFeedback,
  CFormInput, CFormLabel,
  CInputGroup,
  CInputGroupText,
  CLoadingButton, CTooltip
} from "@coreui/react-pro";
import Project from "../type/Project";
import Researcher from "../type/Researcher";
import api from "../api";
import CIcon from "@coreui/icons-react";
import {cilLockLocked, cilUser} from "@coreui/icons";
import setAuthorizationHeader from "../utils/setAuthorizationHeader";
import User from "../type/User";
import ValidationViolationsParser from "../utils/ValidationViolationsParser";
import {Link} from "react-router-dom";
import appRoutes from "../appRoutes";
import classNames from "classnames";

const ProjectRegisterResearcherForm = (props: {
  project: Project,
  researcher?: Researcher,
}) => {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<{[key: string]: string[]}>({});
  const [regulations, setRegulations] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [firstname, setFirstname] = React.useState<string>('');
  const [plainPassword, setPlainPassword] = React.useState<string>('');
  const [isEmailExists, setIsEmailExists] = React.useState<null|boolean>(null);
  const [researcher, setResearcher] = React.useState(props.researcher);
  const [created, setCreated] = React.useState(false);

  const isResearcher = (researcher: any): researcher is Researcher => {
    return researcher['@type'] === 'Researcher'
  }

  const isEmail = (email: string): boolean => {
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    return regexp.test(email)
  }

  const submit = () => {
    setLoading(true)
    setError('')
    setErrors({})

    if(researcher){
      api.project.registerResearcher( {
        project: props.project['@id'],
        firstname: researcher.firstname,
        email: researcher.email,
        plainPassword: researcher.plainPassword,
      })
        .then(res => setCreated(true))
        .catch(res => {
          if(res?.response?.status === 422){
            setErrors(ValidationViolationsParser(res?.response?.data?.violations))
          }
          if(res?.response?.status === 500){
            setError(res?.response?.data['hydra:description']);
          }
        })
        .finally(() => setLoading(false))
    }else if(isEmailExists === null){
      api.researcher.emailExists( email)
        .then(res => {
          setIsEmailExists(res.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }else if(isEmailExists){
      api.auth({
        email,
        password: plainPassword,
      })
        .then(token => {
          setAuthorizationHeader(token);
          api.me()
            .then(researcher => isResearcher(researcher) && setResearcher(researcher))

        })
        .catch(res => {
          setError('Błędne hasło')
        })
        .finally(() => {
          setLoading(false)
        })
    }else if(!isEmailExists){
      api.project.registerResearcher( {
        project: props.project['@id'],
        firstname,
        email,
        plainPassword,
      })
        .then(res => {
          setCreated(true);
          setResearcher(res.data)
        })
        .catch(res => {
          if(res?.response?.status === 422){
            setErrors(ValidationViolationsParser(res?.response?.data?.violations))
          }
          if(res?.response?.status === 500){
            setError(res?.response?.data['hydra:description']);
          }
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <CForm onSubmit={e => {
      e.preventDefault()
      if(!isEmail(email)){
        setErrors({
          ...errors,
          email: ['Adres e-mail jest niepoprawny']
        })
        return;
      }
      submit()
    }}>
      {error && <CAlert color={'danger'}>{error}</CAlert>}

      {
        researcher
          ? <div>
            <h4 className="text-center">Witaj {researcher.firstname}</h4>
          </div>
          : <React.Fragment>
            <CInputGroup className="mb-3">
              <CInputGroupText>@</CInputGroupText>
              <CFormInput invalid={!!errors?.email} placeholder="Email" autoComplete="email" onChange={e => setEmail(e.target.value)} value={email} disabled={isEmailExists !== null} />
              {errors?.email && <CFormFeedback invalid>{errors?.email.join('; ')}</CFormFeedback>}
            </CInputGroup>

            {isEmailExists === true && <CInputGroup className="mb-3">
              <CInputGroupText>
                <CIcon icon={cilLockLocked} />
              </CInputGroupText>
              <CFormInput type={'password'} invalid={!!errors?.plainPassword} placeholder="Hasło" autoComplete="plainPassword" onChange={e => setPlainPassword(e.target.value)} value={plainPassword}  />
              {errors?.plainPassword && <CFormFeedback invalid>{errors?.plainPassword.join('; ')}</CFormFeedback>}
            </CInputGroup>}

            {isEmailExists === false && <React.Fragment>
              <CInputGroup className="mb-3">
                <CInputGroupText>
                  <CIcon icon={cilUser} />
                </CInputGroupText>
                <div className="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
                  <div className="d-flex flex-column flex-grow-1">
                    <CFormInput invalid={!!errors?.firstname} placeholder="Imię" autoComplete="firstname" onChange={e => setFirstname(e.target.value)} value={firstname} />
                    {errors?.firstname && <CFormFeedback invalid>{errors?.firstname.join('; ')}</CFormFeedback>}
                  </div>
                  <CTooltip content={'Nazwa jest  widoczna na liście uczestników i w oknie czatów'}><i className="fas fa-info-circle ms-2" /></CTooltip>
                </div>

              </CInputGroup>
              <CInputGroup className="mb-3">
                <CInputGroupText>
                  <CIcon icon={cilLockLocked} />
                </CInputGroupText>
                <CFormInput type={'password'} invalid={!!errors?.plainPassword} placeholder="Hasło" autoComplete="plainPassword" onChange={e => setPlainPassword(e.target.value)} value={plainPassword}  />
                {errors?.plainPassword && <CFormFeedback invalid>{errors?.plainPassword.join('; ')}</CFormFeedback>}
              </CInputGroup>
            </React.Fragment>}

            {isEmailExists !== null && <div className="d-flex flex-row flex-nowrap align-items-center cursor-pointer mb-3" onClick={() => setRegulations(!regulations)}>
              {
                regulations
                  ? <i className="far fa-check-square" />
                  : <i className="far fa-square" />
              }
              <CFormLabel className={classNames({
                'ms-2 mb-0 cursor-pointer': true,
                'text-muted': !errors?.regulations || regulations,
                'text-danger': errors?.regulations && !regulations,
              })} style={{ lineHeight: '15px' }}><small>Administratorem danych osobowych jest SW Research sp. z o.o. z siedzibą w Warszawie ul. Jarosława Dąbrowskiego 64a, 02-561 Warszawa. Zostaną one przetworzone, zgodnie z naszą <a className="text-secondary" href="https://swquality.pl/klauzula-informacyjna" rel="noreferrer" target={'_blank'}>polityką przetwarzania danych osobowych</a>, w celach przekazywania informacji marketingowych i promocyjnych oraz w celach analitycznych.</small></CFormLabel>
            </div>}
          </React.Fragment>
      }

      {
        created
          ? <CAlert className="d-flex flex-column justify-content-center mt-2" color={'success'}>
            <p className="text-center">Dostęp do projektu został poprawnie nadany.</p>
            <Link className="btn btn-primary mx-auto" to={appRoutes.login}>Zaloguj się</Link>
          </CAlert>
          : <div className="d-grid">
            <CLoadingButton
              color={'primary'}
              loading={loading}
              onClick={() => {
                if(!isEmail(email)){
                  setErrors({
                    ...errors,
                    email: ['Adres e-mail jest niepoprawny']
                  })
                  return;
                }else{
                  if(isEmailExists !== null && !regulations){
                    setErrors({
                      ...errors,
                      regulations: ['Musisz zaakceptować']
                    })
                    return;
                  }
                }

                submit()
              }}
            >{!researcher ? 'Dalej' : 'Przyznaj dostęp'}</CLoadingButton>
          </div>
      }
    </CForm>
  )
};

export default ProjectRegisterResearcherForm;
