import React from 'react';
import Project from "../../type/Project";
import ProjectMeasurement from "../../type/ProjectMeasurement";
import useDidMountEffect from "../../utils/useDidMountEffect";
import api from "../../api";
import dateFormatter from "../../utils/dateFormatter";
import {orderBy} from "lodash";
import {Link} from 'react-router-dom';
import appRoutes from "../../appRoutes";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CBadge,
  CSpinner,
  CTooltip
} from "@coreui/react-pro";
import classNames from "classnames";
import useAfterUpdateEffect from "../../utils/useAfterUpdateEffect";
import moment from "moment";
import {isMobile} from "react-device-detect";


type props = {
  project: Project
  activeId: string
}

type groupList = {
  label: string
  projectMeasurements: ProjectMeasurement[]
}

const ProjectMeasurementsListCollapse = (props: props) => {
  const [activeItemKey, setActiveItemKey] = React.useState<number | null>();
  const [projectMeasurements, setProjectMeasurements] = React.useState<ProjectMeasurement[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const [showList, setShowList] = React.useState(true);

  useDidMountEffect(() => {
    setLoading(true)

    api.projectMeasurement.list({
      project: props.project.id,
      itemsPerPage: 9999
    })
      .then((res) => setProjectMeasurements(res.data['hydra:member']))
      .finally(() => setLoading(false))
  })

  useAfterUpdateEffect(() => {
    getGroupedList().forEach((group, key) => {
      if (group.projectMeasurements.find(projectMeasurement => projectMeasurement.id === props.activeId)) {
        setActiveItemKey(key + 1)
        return
      }
    })
  }, [projectMeasurements])

  const getGroupedList = (): groupList[] => {
    const groups: groupList[] = []
    orderBy(projectMeasurements, ['startAt'], ['asc']).forEach(projectMeasurement => {
      const date: string = dateFormatter(projectMeasurement.startAt)

      if (!groups.find(group => group.label === date)) {
        groups.push({
          label: date,
          projectMeasurements: []
        })
      }
      groups.find(group => group.label === date)?.projectMeasurements.push(projectMeasurement)
    })

    return groups;
  }

  return <div>
    {showList && <CTooltip content={'Ukryj menu'} placement={'bottom'}><i className="fas fa-chevron-right py-2 px-1 cursor-pointer" onClick={() => setShowList(!showList)}/></CTooltip>}
    {!showList && <CTooltip content={'Pokaż menu'} placement={'bottom'}><div className="cursor-pointer py-2 px-1" onClick={() => setShowList(!showList)}><i className="fas fa-chevron-left ms-2"/></div></CTooltip>}
    {showList && <div
      className={classNames({
        'position-relative mb-0': true,
        'w-100': isMobile,
      })} style={{
      overflowY: 'auto',
      width: show ? '250px' : '0px',
      minWidth: show ? '250px' : '0px',
      maxHeight: 'calc(100vh - 81px)'
    }}
    >
      {
        loading
          ? <CSpinner className="d-block mx-auto"/>
          : <React.Fragment>
            <div
              className="cursor-pointer"
              onClick={() => setShow(!show)}
              style={{
                position: 'absolute',
                left: 0,
                top: '-40px'
              }}
            >
              {show && <CTooltip content={show ? 'Ukryj listę pomiarów' : 'Pokaż listę pomiarów'} placement={'bottom'}><i
                className="fas fa-chevron-left py-2"
              /></CTooltip>}
              {!show && <CTooltip content={show ? 'Ukryj listę pomiarów' : 'Pokaż listę pomiarów'} placement={'bottom'}><i
                className="fas fa-chevron-right py-2"
              /></CTooltip>}
            </div>
            {show && <CAccordion activeItemKey={activeItemKey || undefined}>
              {getGroupedList().map((group, key) => {
                return <CAccordionItem key={key} itemKey={key + 1}>
                  <CAccordionHeader onClick={() => setActiveItemKey(key)}>
            <span
              className={classNames({
                'text-secondary': key !== activeItemKey,
              })}
            >{group.label} ({group.projectMeasurements.length})</span>
                  </CAccordionHeader>
                  <CAccordionBody className="d-flex flex-column p-0">
                    {group.projectMeasurements.map(projectMeasurement => {
                      return <Link
                        className={classNames({
                          'list-group-item d-flex flex-row flex-nowrap align-items-center justify-content-between': true,
                          'bg-primary text-white': projectMeasurement.id === props.activeId,
                        })}
                        key={projectMeasurement.id}
                        to={appRoutes.projectMeasurement.view(projectMeasurement.id)}
                      >
                        <span>{projectMeasurement.name}</span>
                        <span className="ps-2">{moment(projectMeasurement.startAt).format('HH:mm')}</span>
                      </Link>
                    })}
                  </CAccordionBody>
                </CAccordionItem>
              })}
            </CAccordion>}
          </React.Fragment>
      }
    </div>}
  </div>
};

export default ProjectMeasurementsListCollapse;
