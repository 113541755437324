import React from 'react';
import Message from "../../../type/ProjectMeasurement/Chat/Message";
import moment from "moment";

type props = {
  messages: (Message & {
    researcherFirstname?: string
  })[]
}

const ChatMessages = (props: props) => {
  return (
    <div>
      {props.messages.map(message => {
        return <div key={message.id} className="d-flex flex-column mb-2">
          <div className="d-flex justify-content-between text-secondary" style={{
            fontSize: '11px',
            lineHeight: '9px'
          }}>
            <span>{message?.researcherFirstname || 'Administrator'}</span>
            <span>{moment(message.createdAt).format('HH:mm')}</span>
          </div>
          <span style={{
            lineHeight: '14px',
            fontSize: '13px',
          }}>{message.msg}</span>
        </div>
      })}
    </div>
  );
};

export default ChatMessages;
