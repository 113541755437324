import axios, {AxiosResponse} from 'axios'
import User from "./type/User";
import Researcher from "./type/Researcher";
import parseObjectToUrlParams from "./utils/parseObjectToUrlParams";
import Project from "./type/Project";
import ProjectMeasurement from "./type/ProjectMeasurement";
import Respondent from "./type/Respondent";

export type listParamsType = {
  [key: string]: string|number|undefined
}
const defaultSort = {
  'order[createdAt]': 'desc'
}

const api = {
  auth: (data: {
    email: string
    password: string
  }): Promise<string> => axios.post('/authentication_token', data).then(res => res.data.token),
  remindPassword: (data: {
    email: string
  }): Promise<any> => axios.post('/api/remind-password', data).then(res => res.data),
  me: (): Promise<User|Researcher> => axios.get<User|Researcher>('/api/me').then(res => res.data),
  history: {
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/histories?' + parseObjectToUrlParams(params)),
  },
  google: {
    token: (): Promise<AxiosResponse> => axios.get('/api/connect/quality-google/token'),
  },
  project: {
    clearChatsInSampleProject: (): Promise<AxiosResponse<any>> => axios.get('/api/clear-chats-in-sample-project'),
    create: (data: any): Promise<AxiosResponse<Project>> => axios.post('/api/projects', data),
    registerResearcher: (data: any): Promise<AxiosResponse<Researcher>> => axios.post(`/api/project-register-researcher`, data),
    delete: (id: number|string|undefined): Promise<AxiosResponse<Project>> => axios.delete(`/api/projects/${id}`),
    get: (id: number|string|undefined): Promise<AxiosResponse<Project>> => axios.get(`/api/projects/${id}`),
    getResearchers: (id: number|string|undefined): Promise<AxiosResponse> => axios.get(`/api/projects/${id}/researchers`),
    getByRegisterId: (registerId: number|string|undefined): Promise<AxiosResponse<Project>> => axios.get(`/api/project-register?id=${registerId}`),
    update: (id: number|string|undefined, data: any): Promise<AxiosResponse<Project>> => axios.patch(`/api/projects/${id}`, data),
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/projects?' + parseObjectToUrlParams(params)),
    researchers: (projectId: number|string|undefined, data: any): Promise<AxiosResponse> => axios.post(`/api/projects/${projectId}/researchers`, data),
    sendInvitation: (data: any): Promise<AxiosResponse> => axios.post('/api/projects/send-invitation', data),
  },
  projectResearcher: {
    create: (data: any): Promise<AxiosResponse<Project>> => axios.post('/api/project-researchers', data),
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/project-researchers?' + parseObjectToUrlParams(params)),
    delete: (id: string): Promise<AxiosResponse<Project>> => axios.delete(`/api/project-researchers/${id}`),
  },
  projectShare: {
    create: (data: any): Promise<AxiosResponse<Project>> => axios.post('/api/project-shares', data),
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/project-shares?' + parseObjectToUrlParams(params)),
    delete: (id: string): Promise<AxiosResponse<Project>> => axios.delete(`/api/project-shares/${id}`),
  },
  projectMeasurement: {
    get: (id: string): Promise<AxiosResponse<ProjectMeasurement>> => axios.get(`/api/project-measurements/${id}`),
    getChats: (id: string): Promise<AxiosResponse> => axios.get(`/api/project-measurements/${id}/chats`),
    create: (data: any): Promise<AxiosResponse<ProjectMeasurement>> => axios.post('/api/project-measurements', data),
    createFromItems: (data: any): Promise<AxiosResponse<ProjectMeasurement>> => axios.post('/api/project-measurements/create-from-items', data),
    update: (id: string, data: any): Promise<AxiosResponse<ProjectMeasurement>> => axios.patch(`/api/project-measurements/${id}`, data),
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/project-measurements?' + parseObjectToUrlParams({
      ...defaultSort,
      ...params
    })),
    delete: (id: string): Promise<AxiosResponse<ProjectMeasurement>> => axios.delete(`/api/project-measurements/${id}`),
    chat: {
      getMessages: (id: string): Promise<AxiosResponse> => axios.get(`/api/project-measurements/chats/${id}/messages`),
      message: {
        create: (data: any): Promise<AxiosResponse> => axios.post('/api/project-measurements/chats/messages', data),
      }
    },
  },
  projectMeasurementRespondents: {
    create: (data: any): Promise<AxiosResponse> => axios.post('/api/project-measurement-respondents', data),
    createMultiRespondents: (data: any): Promise<AxiosResponse> => axios.post('/api/project-measurement-respondents-multi-respondents', data),
    update: (id: string, data: any): Promise<AxiosResponse> => axios.patch(`/api/project-measurement-respondents/${id}`, data),
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/project-measurement-respondents?' + parseObjectToUrlParams(params)),
    delete: (id: string): Promise<AxiosResponse> => axios.delete(`/api/project-measurement-respondents/${id}`),

    notificationSent: {
      list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/projects/project-measurements-respondents/notification-sents?' + parseObjectToUrlParams(params)),
    },
  },
  youtubeStreams: {
    get: (id: string): Promise<AxiosResponse> => axios.get(`/api/youtube-streams/${id}`),
    update: (id: string, data: any): Promise<AxiosResponse> => axios.patch(`/api/youtube-streams/${id}`, {
      token: data.token,
      broadcastedAt: data.broadcastedAt,
      description: data.description,
      title: data.title,
      status: data.status
    }),
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/youtube-streams?' + parseObjectToUrlParams(params)),
    delete: (id: string, token: string): Promise<AxiosResponse> => axios.delete(`/api/youtube-streams/${id}?token=${token}`),
    bind: (id: string, data: any): Promise<AxiosResponse> => axios.patch(`/api/youtube-streams/${id}/bind`, data),
    unbind: (id: string, data: any): Promise<AxiosResponse> => axios.patch(`/api/youtube-streams/${id}/unbind`, data),
    create: (data: any): Promise<AxiosResponse> => axios.post(`/api/youtube-streams`, data),
    connect: (id: string, youtubeId: string, email: string): string => window.location.origin + `/api/connect/quality-google?project=${id}&youtube=${youtubeId}&email=${email}`,
  },
  youtubeAccount: {
    get: (id: string): Promise<AxiosResponse> => axios.get(`/api/youtube-accounts/${id}`),
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/youtube-accounts?' + parseObjectToUrlParams(params)),
  },
  zoomAccount: {
    get: (id: string): Promise<AxiosResponse> => axios.get(`/api/zoom-accounts/${id}`),
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/zoom-accounts?' + parseObjectToUrlParams(params)),
  },
  zoomMeeting: {
    get: (id: string): Promise<AxiosResponse> => axios.get(`/api/zoom-meetings/${id}`),
    update: (id: string, data: any): Promise<AxiosResponse> => axios.patch(`/api/zoom-meetings/${id}`, {
      token: data.token,
      meetingAt: data.meetingAt,
      alternateHost: data.alternateHost,
      title: data.title,
      status: data.status
    }),
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/zoom-meetings?' + parseObjectToUrlParams(params)),
    delete: (id: string, token: string): Promise<AxiosResponse> => axios.delete(`/api/zoom-meetings/${id}?token=${token}`),
    livestream: (id: string, data: any): Promise<AxiosResponse> => axios.patch(`/api/zoom-meetings/${id}/livestream`, data),
    create: (data: any): Promise<AxiosResponse> => axios.post(`/api/zoom-meetings`, data),
    connect: (id: string, email: string): string => window.location.origin + `/api/connect/quality-zoom?project=${id}&email=${email}`,
  },
  researcher: {
    getToken: (key: any): Promise<AxiosResponse<string>> => axios.get(`/api/researcher/get-token?key=${key}`),
    registerToSampleProject: (data: any): Promise<AxiosResponse<Researcher>> => axios.post(`/api/researchers/register-to-sample-project`, data),
    confirmAccount: (confirmKey: any): Promise<AxiosResponse<number>> => axios.get('/api/researcher/confirm-account?confirmKey=' + confirmKey),
    create: (date: any): Promise<AxiosResponse<Researcher>> => axios.post('/api/researchers', date),
    emailExists: (email: string): Promise<AxiosResponse<boolean>> => axios.get(`/api/researcher/email-exists?email=${email}`),
    get: (id: string): Promise<AxiosResponse<Researcher>> => axios.get(`/api/researchers/${id}`),
    update: (id: string, data: any): Promise<AxiosResponse<Researcher>> => axios.patch(`/api/researchers/${id}`, data),
    createFromText: (text: string): Promise<AxiosResponse> => axios.post('/api/researchers/create-from-text', {text}),
    delete: (id: string): Promise<AxiosResponse> => axios.delete(`/api/researchers/${id}`),
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/researchers?' + parseObjectToUrlParams(params)),
  },
  respondent: {
    create: (data: any): Promise<AxiosResponse<Respondent>> => axios.post('/api/respondents', data),
    get: (id: string): Promise<AxiosResponse<Respondent>> => axios.get(`/api/respondents/${id}`),
    update: (id: string, data: any): Promise<AxiosResponse<Respondent>> => axios.patch(`/api/respondents/${id}`, data),
    delete: (id: string): Promise<AxiosResponse> => axios.delete(`/api/respondents/${id}`),
    sendSms: (data: any): Promise<AxiosResponse<any>> => axios.post('/api/respondents/send-sms', data),
    createFromText: (text: string): Promise<AxiosResponse> => axios.post('/api/respondents/create-from-text', {text}),
    list: (params: listParamsType): Promise<AxiosResponse> => axios.get('/api/respondents?' + parseObjectToUrlParams({
      ...defaultSort,
      ...params
    })),
  },

}

export default api
