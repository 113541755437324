import moment from "moment";

const DateFormatter = (date: string, time = false): string => {
  if(time){
    return moment(date).format('YYYY-MM-DD HH:mm');
  }else{
    return moment(date).format('YYYY-MM-DD');
  }
};

export default DateFormatter;
