import React, {useEffect} from 'react';
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CFormInput,
  CInputGroup,
  CInputGroupText, CLoadingButton, CModal, CModalBody, CModalFooter,
  CModalHeader
} from "@coreui/react-pro";
import Chat from "../../../type/ProjectMeasurement/Chat";
import api from "../../../api";
import ChatMessages from "./ChatMessages";
import Message from "../../../type/ProjectMeasurement/Chat/Message";
import useDidMountEffect from "../../../utils/useDidMountEffect";
import useAfterUpdateEffect from "../../../utils/useAfterUpdateEffect";
import CKEditor from "../../../componentsReusable/CKEditor";

type props = {
  chat: Chat
  height: number
  onMsgNew: (messagesCount: number) => void
}

const MSG_MAX_LENGTH = 2000;
const ChatDisplay = (props: props) => {
  const [msg, setMsg] = React.useState('');
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  useDidMountEffect(() => {
    fetchMessages()

    setInterval(() => {
      fetchMessages()
    }, 1800)
  })

  const sendMsg = () => {
    if(!msg){
      return;
    }

    if(msg.length > MSG_MAX_LENGTH){
      setModalOpen(true)

      return;
    }

    api.projectMeasurement.chat.message.create({
      chat: props.chat['@id'],
      msg,
    })
      .then(() => {
        setMsg('')
        fetchMessages()
      })
  }

  const fetchMessages = () => {
    api.projectMeasurement.chat.getMessages(props.chat.id)
      .then(res => {
        if(res.data['hydra:member'].length !== messages.length){
          setMessages(messagesLast => {
            if(res.data['hydra:member'].length !== messagesLast.length){
              setTimeout(() => {
                const msgEl = document.getElementById(`chat-${props.chat.id}.messages`)
                if(msgEl){
                  msgEl?.scrollTo(0, 9999999999)
                }
              }, 1)

              props.onMsgNew(res.data['hydra:member'].length - messagesLast.length)

              return res.data['hydra:member']
            }
            return messagesLast
          })
        }
      })
  }

  return (
    <CCard style={{height: `${props.height}%`}}>
      <CCardHeader className="bg-info text-white">{props.chat.moderator ? 'Moderator' : 'Czat ogólny'}</CCardHeader>
      <CCardBody className="p-1" id={`chat-${props.chat.id}.messages`} style={{
        overflow: 'hidden',
        overflowY: 'scroll'
      }}>
        <ChatMessages messages={messages} />
      </CCardBody>
      <CCardFooter className="p-0 border-0">
        <CInputGroup>
          <CFormInput
            placeholder="Wiadomość..."
            onChange={e => setMsg(e.target.value)}
            onKeyDown={e => {
              if(e.key === 'Enter'){
                e.preventDefault()
                sendMsg()
              }
            }}
            value={msg}
          />
          <CInputGroupText className="cursor-pointer" onClick={sendMsg}><i className="fas fa-paper-plane" /></CInputGroupText>
        </CInputGroup>
      </CCardFooter>

      {modalOpen && <CModal size={'lg'} visible onClose={() => setModalOpen(false)}>
        <CModalHeader className="bg-warning">Ostrzeżenie</CModalHeader>
        <CModalBody>
          <CAlert color={'warning'} className="d-flex align-items-center ">
            <i className="fas fa-exclamation-triangle fa-2x me-2" />
            <span>Treść wpisanej wiadomości zawiera {msg.length} znaków, maksymalnie można wysłać {MSG_MAX_LENGTH} znaków.</span>
          </CAlert>
        </CModalBody>
        <CModalFooter>
          <CButton color={'secondary'} onClick={() => setModalOpen(false)}>Zamknij</CButton>
        </CModalFooter>
      </CModal>}
    </CCard>
  );
};

export default ChatDisplay;
