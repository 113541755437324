import React from 'react'
import {CAlert, CButton, CSpinner, CTabPane, CTooltip, CNav, CNavItem, CNavLink, CTabContent} from "@coreui/react-pro";
import {useParams} from "react-router-dom";
import ProjectMeasurement from "../../type/ProjectMeasurement";
import useDidMountEffect from "../../utils/useDidMountEffect";
import api from "../../api";
import ProjectMeasurementsListCollapse from "../../components/ProjectMeasurement/ProjectMeasurementsListCollapse";
import useAfterUpdateEffect from "../../utils/useAfterUpdateEffect";
import classNames from "classnames";
import parse from "html-react-parser";
import ProjectMeasurementChats from "../../components/ProjectMeasurement/ProjectMeasurementChats";
import Project from "../../type/Project";
import {isMobile} from "react-device-detect";

const ProjectMeasurementView = (): JSX.Element => {
  const params = useParams<{id: string}>();
  const [forbidden, setForbidden] = React.useState(false);
  const [project, setProject] = React.useState<Project>();
  const [projectMeasurement, setProjectMeasurement] = React.useState<ProjectMeasurement>();
  const [loading, setLoading] = React.useState(false);
  const [stream, setStream] = React.useState(true);
  const [tabActive, setTabActive] = React.useState('description');

  useAfterUpdateEffect(() => fetch(), [params])

  useAfterUpdateEffect(() => {
    const title = ['SW Quality'];


    if(projectMeasurement){
      if(projectMeasurement.project?.name){
        title.push(projectMeasurement.project?.name)
      }

      title.push(projectMeasurement.name)
    }else{
      if(project){
        title.push(project.name)
      }
    }

    document.title = title.join(' - ')
  }, [project, projectMeasurement])

  useDidMountEffect(() => {
    fetch()
  })

  const fetch = () => {
    setLoading(true)
    setProject(undefined)
    setProjectMeasurement(undefined)

    api.projectMeasurement.get(params.id)
      .then(res => {
        setProjectMeasurement(res.data)
      })
      .catch(res => {
        if(res.response.status === 403){
          setForbidden(true)
        }

        if(res.response.status === 404){
          fetchProject();
        }
      })
      .finally(() => setLoading(false))
  }

  const fetchProject = () => {
    setLoading(true)

    api.project.get(params.id)
      .then(res => setProject(res.data))
      .catch(res => setForbidden(true))
      .finally(() => setLoading(false))
  }

  const getProject = (): Project|null => {
    if(projectMeasurement?.project){
      return projectMeasurement.project
    }
    if(project){
      return project
    }

    return null
  }

  if(forbidden){
    return <CAlert className="mx-auto mt-2" color={'danger'}>Brak dostępu do tego pomiaru</CAlert>
  }

  if(loading){
    return <div className="d-flex flex-nowrap justify-content-center mt-5">
      <CSpinner />
    </div>
  }

  return <div className="d-flex flex-column flex-md-row flex-nowrap flex-grow-1 position-relative">
    {(getProject() && (!projectMeasurement || !isMobile)) ? <ProjectMeasurementsListCollapse
      //@ts-ignore
      project={getProject()}
      activeId={params.id}
    /> : <div />}
    {(project && !projectMeasurement) && <div className="w-100 d-flex flex-column justify-content-center align-items-start h-100">
      <CAlert color={'info'} className="mx-auto">Wybierz pomiar dostępny w menu z lewej strony</CAlert>

      {project.description && <div className="px-2">
        {parse(project.description)}
      </div>}
    </div>}
    {projectMeasurement && <div className="d-flex flex-column flex-grow-1 px-3 h-100" style={{maxHeight: 'calc(100vh - 81px)'}}>
      <div className="position-absolute" style={{
        top: '-40px'
      }}>
        <CTooltip content={'Pokaż/Ukryj stream'}>
          <i className={classNames({
            'fab fa-youtube p-2 cursor-pointer': true,
            'text-primary': stream,
            'text-secondary': !stream,
          })} onClick={() => setStream(!stream)}/>
        </CTooltip>
      </div>

      {stream && <div className="flex-grow-1">
        {projectMeasurement.stream ? <iframe
            style={{width: '100%', height: '100%', minHeight: '58vh'}}
            className="d-block mx-auto"
            src={`https://www.youtube.com/embed/${projectMeasurement.stream}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen/>
          : <CAlert color="danger">Brak linku do streamu</CAlert>
        }
      </div>}

      <CNav variant="underline">
        <CNavItem>
          <CNavLink active={tabActive === 'description'} onClick={() => setTabActive('description')}>Opis</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink active={tabActive === 'scenario'} onClick={() => setTabActive('scenario')}>Scenariusz</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink active={tabActive === 'transcription'} onClick={() => setTabActive('transcription')}>Transkrypcja</CNavLink>
        </CNavItem>
      </CNav>
      <CTabContent className="flex-grow-1" style={{overflowY: 'auto'}}>
        <CTabPane visible={tabActive === 'scenario'}>
          {
            projectMeasurement.scenario
              ? <span>{parse(projectMeasurement.scenario)}</span>
              : (projectMeasurement.project && projectMeasurement.project.scenario)
                ? <span>{parse(projectMeasurement.project.scenario)}</span>
                : null
          }
        </CTabPane>
        <CTabPane visible={tabActive === 'description'}>
          {
            projectMeasurement.description
              ? <span>{parse(projectMeasurement.description)}</span>
              : (projectMeasurement.project && projectMeasurement.project.description)
                ? <span>{parse(projectMeasurement.project.description)}</span>
                : null
          }
        </CTabPane>
        <CTabPane visible={tabActive === 'transcription'}>
          {parse(projectMeasurement.transcription)}
        </CTabPane>
      </CTabContent>
    </div>}
    {projectMeasurement && <ProjectMeasurementChats projectMeasurement={projectMeasurement}/>}
    {(project && !projectMeasurement) && <div style={{ width: '300px' }} />}
  </div>
}

export default ProjectMeasurementView
