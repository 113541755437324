import React, {Suspense} from 'react'
import { CSpinner} from "@coreui/react-pro";
import {Route, Switch} from "react-router-dom";
import appRoutes from "../appRoutes";
import ProjectRegisterResearcherView from "../views/Project/ProjectRegisterResearcherView";

const ProjectEmptyLayout = (): JSX.Element => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <Suspense fallback={<CSpinner color="primary" />}>
          <Switch>
            <Route
              path={appRoutes.projects.registerResearcher()}
              exact
              render={() => <ProjectRegisterResearcherView />}
            />
          </Switch>
        </Suspense>
    </div>
  )
}

export default ProjectEmptyLayout
