import { USER_SET } from './actions'
import User from '../../type/User'

export type userAction = {
  type: string
  user: User|null
}

const initialState = null
export const reducer = (state = initialState, action: userAction): User|null => {
  switch (action.type) {
    case USER_SET:
      return action.user

    default:
      return state
  }
}
