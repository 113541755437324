import {DependencyList, useEffect, useRef} from 'react';

const useAfterUpdateEffect = (effect: () => void, props?: DependencyList | undefined): void => {
  const initial = useRef(true);

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
    }else{
      effect()
    }
  }, props)
}

export default useAfterUpdateEffect;
