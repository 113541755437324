import React, { Suspense } from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";
import "@fortawesome/fontawesome-free/scss/regular.scss";
import "@fortawesome/fontawesome-free/scss/brands.scss";
import "react-datepicker/dist/react-datepicker.min.css";
import appRoutes from './appRoutes'
import Login from './views/Login'
import setAuthorizationHeader from "./utils/setAuthorizationHeader";
import {useTypedSelector} from "./store";
import api from "./api";
import {useDispatch} from "react-redux";
import GuestRoute from "./routes/GuestRoute";
import AuthRoute from "./routes/AuthRoute";
import setMe from "./utils/setMe";
import ProjectMeasurementLayout from "./layout/ProjectMeasurementLayout";
import ProjectEmptyLayout from "./layout/ProjectEmptyLayout";
import LoginLayout from "./layout/LoginLayout";

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const App = (): JSX.Element => {
  const dispatch = useDispatch()
  const user = useTypedSelector((state) => state.user)
  const researcher = useTypedSelector((state) => state.researcher)

  if(!user && !researcher){
    if(localStorage.jwt){
      setAuthorizationHeader(localStorage.jwt)
      api.me().then(me => dispatch(setMe(me)))

      return <CSpinner />
    }
  }


  return <BrowserRouter>
    <Suspense fallback={<CSpinner color="primary" />}>
      <Switch>
        <GuestRoute path={appRoutes.login} exact render={() => <LoginLayout />} />
        <GuestRoute path={appRoutes.remindPassword} exact render={() => <LoginLayout />} />
        <GuestRoute path={appRoutes.confirmAccount} exact render={() => <LoginLayout />} />
        <GuestRoute path={appRoutes.sampleProjectRegistration} exact render={() => <LoginLayout />} />
        <GuestRoute path={appRoutes.sampleProjectLogin} exact render={() => <LoginLayout />} />
        <AuthRoute path={appRoutes.projectMeasurement.view(':id')} exact render={() => <ProjectMeasurementLayout />} />
        <Route path={appRoutes.projects.registerResearcher()} exact render={() => <ProjectEmptyLayout />} />

        <AuthRoute path="/" render={() => <DefaultLayout />} />
      </Switch>
    </Suspense>
  </BrowserRouter>
}

export default App
