import React, {Suspense} from 'react'
import {CAlert, CSpinner} from "@coreui/react-pro";
import {Link, Route, Switch, useParams} from "react-router-dom";
import appRoutes from "../appRoutes";
import ProjectMeasurementView from "../views/ProjectMeasurement/ProjectMeasurementView";
import {useTypedSelector} from "../store";
import Project from "../type/Project";
import ProjectMeasurement from "../type/ProjectMeasurement";
import useDidMountEffect from "../utils/useDidMountEffect";
import api from "../api";
import useAfterUpdateEffect from "../utils/useAfterUpdateEffect";
import logo from 'assets/images/logo-dark.png'


const DefaultLayout = (): JSX.Element => {
  const researcher = useTypedSelector(store => store.researcher)
  const params = useParams<{id: string}>();
  const [project, setProject] = React.useState<Project>();
  const [projectMeasurement, setProjectMeasurement] = React.useState<ProjectMeasurement>();

  useDidMountEffect(() => {
    fetch()
  })

  useAfterUpdateEffect(() => {
    fetch()
  }, [params.id])

  const fetch = () => {
    setProject(undefined);
    setProjectMeasurement(undefined);

    if(params?.id){
      api.projectMeasurement.get(params.id)
        .then(res => {
          setProjectMeasurement(res.data)
        })
        .catch(() => {
          api.project.get(params.id)
            .then(res => {
              setProject(res.data)
            })
        })
    }
  }

  return (
    <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
      <div className="d-flex flex-row justify-content-between align-items-top">
        <div>
          {(projectMeasurement && projectMeasurement.project) && <Link className="text-secondary" style={{fontSize: '0.8rem'}} to={appRoutes.projectMeasurement.view(projectMeasurement.project.id)}><i className="fas fa-arrow-left" /> Lista pomiarów</Link>}
          {(project && (!researcher || !researcher.sampleProject)) && <Link className="text-secondary" style={{fontSize: '0.8rem'}} to={appRoutes.projects.list}><i className="fas fa-arrow-left" /> Wróć do listy projektów</Link>}
        </div>
        <img className="d-block mx-auto my-2" src={logo} />
        <div />
      </div>

      {
        (researcher && (researcher.blocked || !researcher.confirmed))
          ? <CAlert className="mx-auto" color={researcher.blocked ? 'danger' : 'warning'}>{researcher.blocked ? 'Twoje konto zostało zablokowane' : 'Twoje konto nie jest aktywne. Kliknij w link aktywacyjny który wysłaliśmy w mailu'}</CAlert>
          : <Suspense fallback={<CSpinner color="primary" />}>
            <Switch>
              <Route
                path={appRoutes.projectMeasurement.view(':id')}
                exact
                render={() => <ProjectMeasurementView />}
              />
            </Switch>
          </Suspense>
      }
    </div>
  )
}

export default DefaultLayout
