import React from 'react';
import ProjectMeasurement from "../../type/ProjectMeasurement";
import Chat from "../../type/ProjectMeasurement/Chat";
import ChatDisplay from "./Chat/ChatDisplay";
import useDidMountEffect from "../../utils/useDidMountEffect";
import api from "../../api";
import {CBadge, CSpinner, CTooltip} from "@coreui/react-pro";
import useAfterUpdateEffect from "../../utils/useAfterUpdateEffect";
import {isMobile} from "react-device-detect";
import classNames from "classnames";

type props = {
  projectMeasurement: ProjectMeasurement
}

const ProjectMeasurementChats = (props: props) => {
  const [chats, setChats] = React.useState<Chat[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const [newMessages, setNewMessages] = React.useState(false);

  useAfterUpdateEffect(() => {
    if(show){
      setNewMessages(false)
    }
  }, [show])

  useAfterUpdateEffect(() => {
    if(show){
      setNewMessages(false)
    }
  }, [newMessages])

  useDidMountEffect(() => {
    if(props.projectMeasurement.id){
      setLoading(true)
      api.projectMeasurement.getChats(props.projectMeasurement.id)
        .then(res => setChats(res.data['hydra:member']))
        .finally(() => setLoading(false))
    }
  })

  const getChatByKey = (key: number) => chats[key]

  return (
    <div>
      {loading && <div className="text-center"><CSpinner/></div>}
      {!loading && <React.Fragment>
        <div
          style={{
            position: 'absolute',
            top: '-40px',
            right: 0,
          }}
        >
          {show && <CTooltip content={'Ukryj czaty'} placement={'bottom'}><i className="fas fa-chevron-right py-2 px-1 cursor-pointer" onClick={() => setShow(!show)}/></CTooltip>}
          {!show && <CTooltip content={'Pokaż czaty'} placement={'bottom'}><div className="cursor-pointer py-2 px-1" onClick={() => setShow(!show)}>
            {newMessages && <CBadge color={'danger'}>Nowe wiadomości</CBadge>}
            <i className="fas fa-chevron-left ms-2"/>
          </div></CTooltip>}
        </div>

        <div className={classNames({
          'd-flex flex-column position-relative': true,
          'w-100': isMobile,
        })} style={{
          width: show ? '300px' : 0,
          height: 'calc(100vh - 81px)',
          overflow: 'hidden'
        }}>
          {getChatByKey(0) && <ChatDisplay height={chats.length > 1 ? 50 : 100} key={getChatByKey(0).id} chat={getChatByKey(0)} onMsgNew={messagesCount => setNewMessages(!!messagesCount)} />}
          {getChatByKey(1) && <React.Fragment>
            <ChatDisplay height={50} key={getChatByKey(1).id} chat={getChatByKey(1)} onMsgNew={messagesCount => setNewMessages(!!messagesCount)} />
          </React.Fragment>}
        </div>
      </React.Fragment>}
    </div>
  );
};

export default ProjectMeasurementChats;
